export interface DataTable {
    id: number;
    originalTemplateName: string;
    name: string;
    dateComplete: Date;
    progressStatus: DataTableProgressStatus;
    isComplete: boolean;
    isSnapshot: boolean;
    snapshotComment?: string;
    isAutomatedSnapshot?: boolean;
    snapshotCreatorId?: number;
    originalTableId?: number;
    views: DataTableView[];
    rows: DataTableRow[];
    columns: DataTableColumn[];
    dateCreated: Date;
    isNew: boolean;
    practiceName?: string;
}

export enum DataTableProgressStatus {
    Open,
    InProgress,
    Partial,
    Complete,
}

export const DataTableProgressStatusList = [
    { id: 0, name: 'Open', color: '#000000' },
    { id: 1, name: 'In Progress', color: '#535bd6' },
    { id: 2, name: 'Partial', color: '#deb24d' },
    { id: 3, name: 'Complete', color: '#66cdaa' },
];

export const emptyDataTable: DataTable = {
    id: 0,
    originalTemplateName: '',
    name: '',
    dateComplete: new Date(),
    progressStatus: DataTableProgressStatus.Open,
    isComplete: false,
    isSnapshot: false,
    views: [],
    rows: [],
    columns: [],
    dateCreated: new Date(),
    isNew: false,
};

export interface DataTableColumn {
    id: number;
    customColumnId: string;
    name: string;
    answerType: string;
    formType: string;
    isVisible: boolean;
    formList: string;
    displayOrder: number;
}

export interface DataTableRow {
    id: number;
    customRowId: string;
    isRemovalRequested: boolean;
    isVisible: boolean;
    isSeenByAdmin: boolean;
    isSeenByUser: boolean;
    cells: DataTableViewCell[];
}

export interface DataTableViewCell {
    id: number;
    customColumnId: string;
    answerText: string;
    version: any;
    isSeenByUser: boolean;
    isSeenByAdmin: boolean;
}

export interface DataTableView {
    id: number;
    description: string;
    name: string;
    viewColumns: DataTableViewColumn[];
    dateCreated: Date;
    dueDate: Date;
    originalTableViewId?: number;
    progressStatus: DataTableViewProgressStatus;
    isReadyForReview: boolean;
    isComplete: boolean;
    isSeenByUser: boolean;
}

export enum DataTableViewProgressStatus {
    Open,
    InProgress,
    InReview,
    Confirmed,
}

export const TableViewProgressStatusList = [
    { id: 0, name: 'New', color: '#000000' },
    { id: 1, name: 'In Progress', color: '#535bd6' },
    { id: 2, name: 'In Review', color: '#deb24d' },
    { id: 3, name: 'Confirmed', color: '#66cdaa' },
];

export const emptyTableView: DataTableView = {
    id: 0,
    description: '',
    name: '',
    viewColumns: [],
    dateCreated: new Date(),
    dueDate: null,
    progressStatus: DataTableViewProgressStatus.Open,
    isReadyForReview: false,
    isComplete: false,
    isSeenByUser: true,
};

export interface DataTableViewColumn {
    id: number;
    customColumnId: string;
    isSeenByAdmin: boolean;
    isSeenByUser: boolean;
    overridenDisplayOrder?: number;
    isIncluded?: boolean;
}

export interface DataTableAndTableViewFlatRow {
    rowId: string;
    tableId: number;
    viewId: number;
    name: string;
    dateCreated: Date;
    description?: string;
    rowType: TableRowType;
    practiceName?: string;
    dueDate?: Date;
    progressStatus?: DataTableViewProgressStatus;
}

export enum TableRowType {
    DataTable,
    DataTableView,
}
