import { DataRequestProject } from '../DataRequestProject';
import { DataTableRow } from '../DataTable';
import { TableCellSeenStatus } from '../TableCellSeenStatus';
import {
    ViewColumnSeenStatus,
    RowSeenStatus,
    TableNotificationStatus,
    ViewNotificationStatus,
} from '../TableNotificationStatus';
import { ResetCellInfo } from './data-request-notifications.slice';

export const updateCellsStauts = (
    cellsStatus: TableCellSeenStatus[],
    projects: DataRequestProject[]
) => {
    const dataTables = projects.flatMap((project) => project.dataTables);
    if (dataTables) {
        const cellsSeenStatus = dataTables.flatMap((table) =>
            table.rows.flatMap((row): TableCellSeenStatus[] => {
                const sourceProject = projects.find((project) =>
                    project.dataTables.some(
                        (dataTable) => dataTable.id === table.id
                    )
                );

                const isNewProject = !sourceProject.isSeenByUser;

                const mappedCellsStatus = row.cells.map(
                    (cell): TableCellSeenStatus => {
                        const existingCellStatus = cellsStatus.find(
                            (existingCell) => existingCell.id === cell.id
                        );

                        if (existingCellStatus) {
                            return {
                                ...existingCellStatus,
                            };
                        }

                        return {
                            ...cell,
                            isSeenByUser: isNewProject
                                ? true
                                : cell.isSeenByUser,
                            isSeenByAdmin: cell.isSeenByAdmin,
                            projectId: sourceProject.id,
                            isNewProject: isNewProject,
                            tableId: table.id,
                            customRowId: row.customRowId,
                            isNewTable: false,
                            isNewRow: !row.isSeenByUser,
                        };
                    }
                );

                return mappedCellsStatus;
            })
        );

        return cellsSeenStatus;
    }

    return cellsStatus;
};

export const mapCellsSeenStatus = (
    projects: DataRequestProject[]
): TableCellSeenStatus[] => {
    const dataTables = projects.flatMap((project) => project.dataTables);
    if (dataTables) {
        const cellsSeenStatus = dataTables.flatMap((table) =>
            table.rows.flatMap((row): TableCellSeenStatus[] => {
                const sourceProject = projects.find((project) =>
                    project.dataTables.some(
                        (dataTable) => dataTable.id === table.id
                    )
                );

                const isNewProject = !sourceProject.isSeenByUser;

                const mappedCellsStatus = row.cells.map(
                    (cell): TableCellSeenStatus => {
                        const canCellNotificationBeVisible =
                            !isNewProject &&
                            row.isSeenByUser &&
                            !row.isRemovalRequested &&
                            row.isVisible;

                        return {
                            ...cell,
                            isSeenByUser: canCellNotificationBeVisible
                                ? cell.isSeenByUser
                                : true,
                            isSeenByAdmin: cell.isSeenByAdmin,
                            projectId: sourceProject.id,
                            isNewProject: isNewProject,
                            tableId: table.id,
                            customRowId: row.customRowId,
                            isNewTable: table.isNew,
                            isNewRow: !row.isSeenByUser,
                        };
                    }
                );

                return mappedCellsStatus;
            })
        );

        return cellsSeenStatus;
    }

    return [];
};

export const mapTablesNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    projects: DataRequestProject[],
    isAdminView: boolean
) => {
    const tablesStatuses = initializeTablesStatus(projects);
    const result = updateTablesNotifications(
        cellsSeenStatus,
        tablesStatuses,
        isAdminView
    );

    return result;
};

export const updateTablesNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    tablesStatuses: TableNotificationStatus[],
    isAdminView: boolean
) => {
    const result = tablesStatuses.map((tableStatus) => {
        if (tableStatus.isNewProject) {
            return tableStatus;
        }

        if (tableStatus.isNew) {
            return { ...tableStatus, notificationsCount: 1 };
        }

        const viewNotifications = tableStatus.viewsNotificationsStatus.map(
            (viewStatus) => {
                const viewCells = cellsSeenStatus.filter((cell) =>
                    viewStatus.columnsSeenStatus.some(
                        (viewStatus) =>
                            viewStatus.columnCustomId === cell.customColumnId &&
                            cell.tableId === tableStatus.tableId
                    )
                );

                let viewNotificationCellsCount = 0;

                if (!isAdminView) {
                    if (viewStatus.isSeenByUser) {
                        if (viewStatus.columnsSeenStatus.length) {
                            viewNotificationCellsCount +=
                                getViewCellsNotifications(
                                    viewCells,
                                    tableStatus.rowsSeenStatus,
                                    viewStatus.columnsSeenStatus,
                                    isAdminView
                                );

                            viewNotificationCellsCount += getNewRowsCount(
                                tableStatus.rowsSeenStatus,
                                isAdminView
                            );
                            viewNotificationCellsCount += getNewColumnsCount(
                                viewStatus.columnsSeenStatus,
                                isAdminView
                            );
                        }
                    } else {
                        viewNotificationCellsCount = 1;
                    }
                }

                return {
                    ...viewStatus,
                    notificationsCount: viewNotificationCellsCount,
                };
            }
        );

        const tableNotifications = viewNotifications.reduce(
            (a, b) => a + b.notificationsCount,
            0
        );

        return {
            ...tableStatus,
            viewsNotificationsStatus: viewNotifications,
            notificationsCount: tableNotifications,
        };
    });

    return result;
};

export const resetCellsNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    cellsToReset: ResetCellInfo[],
    tableId: number,
    viewStatus: ViewNotificationStatus
): TableCellSeenStatus[] => {
    const updatedSeenStatuses = cellsSeenStatus.map((cell) => {
        const targetCell = cellsToReset.find(
            (cellToReset) =>
                cellToReset.customRowId === cell.customRowId &&
                cellToReset.customColumnId === cell.customColumnId &&
                tableId === cell.tableId
        );

        const isViewColumnSeenByUser =
            viewStatus?.columnsSeenStatus?.find(
                (column) => column.columnCustomId === cell.customColumnId
            )?.isSeenByUser ?? true;

        if (
            targetCell ||
            cell.isNewTable ||
            cell.isNewRow ||
            !isViewColumnSeenByUser
        ) {
            return {
                ...cell,
                isSeenByAdmin: true,
                isSeenByUser: true,
                isNewTable: false,
                isNewRow: false,
                isNewColumn: false,
            };
        }

        return cell;
    });

    return updatedSeenStatuses;
};

export const resetTableNotifications = (
    tablesNotificationStatus: TableNotificationStatus,
    cellsToReset: ResetCellInfo[],
    viewId: number
): TableNotificationStatus => {
    const isNewView = !tablesNotificationStatus.viewsNotificationsStatus.find(
        (view) => view.viewId === viewId
    )?.isSeenByUser;

    const updatedRowsStauts = tablesNotificationStatus.rowsSeenStatus.map(
        (rowStauts) =>
            cellsToReset.some(
                (cellToReset) =>
                    cellToReset.customRowId === rowStauts.rowCustomId
            ) ||
            tablesNotificationStatus.isNew ||
            tablesNotificationStatus.isNewProject ||
            isNewView
                ? { ...rowStauts, isSeenByAdmin: true, isSeenByUser: true }
                : rowStauts
    );

    const updatedViews = tablesNotificationStatus.viewsNotificationsStatus.map(
        (viewStatus): ViewNotificationStatus => ({
            ...viewStatus,
            columnsSeenStatus: viewStatus.columnsSeenStatus.map(
                (columnStatus) =>
                    (cellsToReset.some(
                        (cell) =>
                            cell.customColumnId === columnStatus.columnCustomId
                    ) &&
                        viewStatus.viewId === viewId) ||
                    tablesNotificationStatus.isNew ||
                    tablesNotificationStatus.isNewProject
                        ? {
                              ...columnStatus,
                              isSeenByAdmin: true,
                              isSeenByUser: true,
                          }
                        : columnStatus
            ),
            isSeenByUser:
                viewStatus.viewId === viewId ? true : viewStatus.isSeenByUser,
        })
    );

    return {
        ...tablesNotificationStatus,
        isNew: false,
        rowsSeenStatus: updatedRowsStauts,
        viewsNotificationsStatus: updatedViews,
    };
};

const initializeTablesStatus = (projects: DataRequestProject[]) => {
    const tablesNotificationsStatuses = projects.flatMap((project) =>
        project.dataTables.map((table): TableNotificationStatus => {
            return {
                tableId: table.id,
                notificationsCount: 0,
                isNew: table.isNew,
                isNewProject: !project.isSeenByUser,
                viewsNotificationsStatus: table.views.map(
                    (view): ViewNotificationStatus => ({
                        viewId: view.id,
                        notificationsCount: 0,
                        columnsSeenStatus: view.viewColumns.map(
                            (viewColumn): ViewColumnSeenStatus => ({
                                columnCustomId: viewColumn.customColumnId,
                                isSeenByAdmin: viewColumn.isSeenByAdmin,
                                isSeenByUser: view.isSeenByUser
                                    ? viewColumn.isSeenByUser
                                    : true,
                            })
                        ),
                        isSeenByUser: view.isSeenByUser,
                    })
                ),
                rowsSeenStatus: table.rows.map(
                    (row): RowSeenStatus => ({
                        rowCustomId: row.customRowId,
                        isSeenByAdmin: row.isSeenByAdmin,
                        isSeenByUser: row.isSeenByUser,
                        isVisible: row.isVisible && !row.isRemovalRequested,
                    })
                ),
            };
        })
    );

    return tablesNotificationsStatuses;
};

const getViewCellsNotifications = (
    viewCells: TableCellSeenStatus[],
    rowsStatus: RowSeenStatus[],
    columnsStatus: ViewColumnSeenStatus[],
    isAdmin: boolean
) => {
    const cellsWithNotifications = viewCells.filter((viewCell) => {
        const isNewRow = !rowsStatus.some(
            (rowStatus) =>
                rowStatus.rowCustomId === viewCell.customRowId &&
                getItemSeenStatus(rowStatus, isAdmin)
        );

        const isNewColumn = !columnsStatus.some(
            (columnStatus) =>
                columnStatus.columnCustomId === viewCell.customColumnId &&
                getItemSeenStatus(columnStatus, isAdmin)
        );

        const result = !viewCell.isSeenByUser && !isNewRow && !isNewColumn;

        return result;
    });

    return cellsWithNotifications?.length ?? 0;
};

const getItemSeenStatus = (
    item: RowSeenStatus | ViewColumnSeenStatus,
    isAdmin: boolean
) => (isAdmin ? item.isSeenByAdmin : item.isSeenByUser);

const getNewRowsCount = (rowsStatus: RowSeenStatus[], isAdmin: boolean) =>
    rowsStatus.filter(
        (row) => !getItemSeenStatus(row, isAdmin) && row.isVisible
    )?.length ?? 0;

const getNewColumnsCount = (
    columnsStatus: ViewColumnSeenStatus[],
    isAdmin: boolean
) =>
    columnsStatus.filter((column) => !getItemSeenStatus(column, isAdmin))
        ?.length ?? 0;
